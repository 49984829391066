import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './style.css';
import { userPaymentResponse } from '../../reducers/commonReducer';

export const PaymentResponse = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get("id");
  const dispatch = useDispatch();
    useEffect(() => {
        dispatch(userPaymentResponse({transaction_id:id}))
    },[])
  const {userPaymentsRespStatus, userPaymentsRespmsg} = useSelector((state) => state.commonReducer);
  return (
    <div className="row response-bg">
        <div className='col-md-3'></div>
        <div className='col-md-6 response-payment'>
          <h1 className={userPaymentsRespStatus === 0 && "text-secondary" || userPaymentsRespStatus === 1 && "text-danger" || userPaymentsRespStatus === 2 && "text-success"}><b>{userPaymentsRespmsg}</b></h1>
        </div>
        <div className='col-md-3'></div>
    </div>
  );
};

