import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {Table, Col, Row, Button} from "react-bootstrap";
import Header from "../../Components/Header";
import { getTickets, restRecord, updateTicket } from "../../reducers/commonReducer";
import SideBaar from "../../Components/SideBaar";
import TicketAction from "./TicketAction";
import moment from "moment";

const TicketList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(restRecord())
    dispatch(getTickets(currentPage));
  }, [navigate, dispatch]);
  const { ticketsData, ticketsDataBlank } = useSelector((state) => state.commonReducer);
  
  return (
    <>
      <Row>  
      <Col md={2} className="p-0"> 
      <SideBaar/>
      </Col> 
      <Col md={10}>
      <Header />
      <div className="container">
        <Row className="mt-4 mb-2 user-table">
          <Col md={6}>
            <h3>Tickets</h3>
          </Col>
          {/* <Col md={6}>
            <TicketAction api={addQuestion} btnTitle={"Add"} btnFloat={"float-right"}/>
          </Col> */}
        </Row>
        <hr/>
        <div className="overflow-auto">
          <Table striped bordered hover size="sm" className="overflow-scroll">
            <thead>
              <tr>
                <th>Sr.</th>
                <th>User Name</th>
                <th>Date</th>
                <th>Question</th>
                <th>Reply</th>
                <th>Admin Reply</th>
                <th>Asked Content</th>
                <th>Img</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {!!ticketsData &&
                ticketsData?.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{data?.user?.name}({data?.user?.username})</td>
                      <td>{moment(data?.createdAt).format("DD/MM/YYYY hh:mm:ss A")}</td>
                      <td>{data?.question?.question}</td>
                      <td>{data?.question?.reply}</td>
                      <td>{data?.reply}</td>
                      <td>{data?.askedContent}</td>
                      <td><img onClick={() => window.open(`/${data?.uploadedImage}`, "_blank")} className="w-50px" src={`/${data?.uploadedImage}`}/></td>
                      <td>{(data?.status === 0 && <span className="text-primary"><b>Pending</b></span>) ||
                        (data?.status === 1 && <span className="text-warning"><b>Progress</b></span>) ||
                        (data?.status === 2 && <span className="text-success"><b>Sorted</b></span>)}</td>
                      <td><TicketAction api={updateTicket} id={data?.id} status={data?.status} reply={data?.reply}/></td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          {
            !!ticketsDataBlank && ticketsDataBlank?.length < 1 ? <h3 className="text-center text-danger">Not Found</h3> : 
            <Button onClick={() => {
              dispatch(getTickets(currentPage + 1))
              setCurrentPage(currentPage + 1)
            }} className="w-100">Load More</Button>
          }
        </div>
      </div>
      </Col>
      </Row>
    </>
  );
};

export default TicketList;
