import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Table, Col, Button, Form } from "react-bootstrap";
import Header from "../../../Components/Header";
import {
  getOptionSubAdmin,
  redBlackGameHistory,
  redBlackTotalResult,
  restRecord,
} from "../../../reducers/commonReducer";
import moment from "moment";
import SideBaar from "../../../Components/SideBaar";
import { fiftyCards } from "../../../const";
import AutoFillDate from "../../../AutoFillDate";

const RedBlackGameHistory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [startDate, setstartDate] = useState(
    // moment().format("YYYY-MM-DDT00:00:00")
  );
  const [endDate, setendDate] = useState(
    // moment().format("YYYY-MM-DDT23:59:59")
  );
  const [subadmin, setSubadmin] = useState();
  const [day, setDay] = useState();

  const [page, setPage] = useState(0);

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(restRecord());
    dispatch(redBlackGameHistory({ currentPage:page }));
    if(localStorage.getItem("userRole") === "1"){
      dispatch(getOptionSubAdmin());
    }
    dispatch(redBlackTotalResult())
  }, []);

  const { redBlackGameList, getOptionSubAdminList, redBlackTotalResultData, redBlackHistoryBlank } = useSelector(
    (state) => state.commonReducer
  );
  const handleSubmit = (e) => {
    dispatch(restRecord())
    dispatch(redBlackGameHistory({
       end:endDate, start:startDate, day, subadmin, currentPage:page
    }))
    dispatch(redBlackTotalResult({
      end:endDate, start:startDate, day,
    }))
  };
  return (
    <>
      <Row>
        <Col md={2} className="p-0">
          <SideBaar />
        </Col>
        <Col md={10}>
          <Header />
          <div className="container">
            <Row className="mt-4 mb-2 user-table">
              <Col md={4}>
                <h3 className="mb-0">Game History</h3>
              </Col>
              <Col md={4}>
                <h3 className="mb-0">Total Betting: {redBlackTotalResultData?.total_betting}</h3>
              </Col>
              <Col md={4}>
                <h3 className="mb-0">Total Winning: {redBlackTotalResultData?.total_winning}</h3>
              </Col>
            </Row>
            <hr className="m-0"/>
            <Row className="mb-2 user-table">
              <Col md={2}>
                <label htmlFor="date">
                  <b>Start Date</b>
                </label>
                <Form.Control
                  type="datetime-local"
                  placeholder="Enter Date"
                  id="date"
                  step="1"
                  value={startDate}
                  onChange={(e) => setstartDate(e.target.value)}
                />
              </Col>
              <Col md={2}>
                <label>
                  <b>End Date</b>
                </label>
                <Form.Control
                  type="datetime-local"
                  placeholder="Enter Date"
                  step="1"
                  value={endDate}
                  onChange={(e) => setendDate(e.target.value)}
                />
              </Col>
              <Col md={2}>
                <AutoFillDate
                  setendDate={setendDate}
                  setstartDate={setstartDate}
                  day={day}
                  setDay={setDay}
                />
              </Col>
              <Col md={2}>
                {/* {localStorage.getItem("userRole") === "1" && (
                  <>
                    <label>
                      <b>SubAdmin</b>
                    </label>
                    <Form.Select
                      value={subadmin}
                      onChange={(e) => setSubadmin(e.target.value)}
                    >
                      <option value="">Select SubAdmin</option>
                      {!!getOptionSubAdminList &&
                  getOptionSubAdminList?.map((data, index) => (
                    <option key={index} value={data?.id}>
                      {data?.username}
                    </option>
                  ))}
                    </Form.Select>
                  </>
                )} */} 
              </Col>
              <Col md={2}>
              </Col>
              <Col md={2}>
                <Button
                  className="mt-4 w-50"
                  variant="success"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
                <Button
                  className="mt-4 w-50"
                  variant="danger"
                  type="submit"
                  onClick={() => window.location.reload(true)}
                >
                  Clear
                </Button>
              </Col>
            </Row>
            <div className="overflow-auto mt-4">
              <Table
                striped
                bordered
                hover
                size="sm"
                className="overflow-scroll"
              >
                <thead>
                  <tr>
                    <th>Sr No.</th>
                    <th>Number</th>
                    <th>Start</th>
                    <th>Total Betting</th>
                    <th>Total Winning</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {!!redBlackGameList &&
                    redBlackGameList?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}.</td>
                          <td>
                            {
                              <img
                                className="small-cards"
                                src={fiftyCards[data?.number]}
                                alt={data?.number}
                              />
                            }
                          </td>
                          <td>
                          {moment(data.start).format("DD/MM/YYYY hh:mm:ss A")}

                            {/* {moment(data.start).format("DD/MM/YYYY hh:mm:ss")} */}
                          </td>
                          <td>{data?.total_betting}</td>
                          <td>{data?.total_winning}</td>
                          <td>
                            <Link to={`/red-black-current-game?id=${data?.id}`}>
                              <Button variant="success">View</Button>
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              {!!redBlackHistoryBlank && redBlackHistoryBlank?.length < 1 ? (
                <h3 className="text-center text-danger">Not Found</h3>
              ) : (
                <Button
                  onClick={() => {
                    dispatch(redBlackGameHistory({ currentPage: page + 1, end:endDate, start:startDate, day, subadmin }));
                    setPage(page + 1);
                  }}
                  className="w-100"
                >
                  Load More
                </Button>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default RedBlackGameHistory;
