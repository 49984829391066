import Diamond from './images/diamond.png';
import Heart from './images/heart.png';
import Joker from './images/joker.png';
import Spade from './images/spade.png';
import Club from './images/club.png';
import Black from './images/black.png';
import Red from './images/redcard.png';
// cards
import c1 from './images/cards/01.png';
import c2 from './images/cards/02.png';
import c3 from './images/cards/03.png';
import c4 from './images/cards/04.png';
import c5 from './images/cards/05.png';
import c6 from './images/cards/06.png';
import c7 from './images/cards/07.png';
import c8 from './images/cards/08.png';
import c9 from './images/cards/09.png';
import c10 from './images/cards/10.png';
import c11 from './images/cards/11.png';
import c12 from './images/cards/12.png';
import c13 from './images/cards/13.png';
import c14 from './images/cards/14.png';
import c15 from './images/cards/15.png';
import c16 from './images/cards/16.png';
import c17 from './images/cards/17.png';
import c18 from './images/cards/18.png';
import c19 from './images/cards/19.png';
import c20 from './images/cards/20.png';
import c21 from './images/cards/21.png';
import c22 from './images/cards/22.png';
import c23 from './images/cards/23.png';
import c24 from './images/cards/24.png';
import c25 from './images/cards/25.png';
import c26 from './images/cards/26.png';
import c27 from './images/cards/27.png';
import c28 from './images/cards/28.png';
import c29 from './images/cards/29.png';
import c30 from './images/cards/30.png';
import c31 from './images/cards/31.png';
import c32 from './images/cards/32.png';
import c33 from './images/cards/33.png';
import c34 from './images/cards/34.png';
import c35 from './images/cards/35.png';
import c36 from './images/cards/36.png';
import c37 from './images/cards/37.png';
import c38 from './images/cards/38.png';
import c39 from './images/cards/39.png';
import c40 from './images/cards/40.png';
import c41 from './images/cards/41.png';
import c42 from './images/cards/42.png';
import c43 from './images/cards/43.png';
import c44 from './images/cards/44.png';
import c45 from './images/cards/45.png';
import c46 from './images/cards/46.png';
import c47 from './images/cards/47.png';
import c48 from './images/cards/48.png';
import c49 from './images/cards/49.png';
import c50 from './images/cards/50.png';
import c51 from './images/cards/51.png';
import c52 from './images/cards/52.png';
import c53 from './images/cards/53.png';
import c54 from './images/cards/54.png';

export var baseUrl = "http://localhost:3000";
//export var baseUrl = "https://livetambola.havflyinvitation.com";
// In case instead of localhost
if (window.location.host) {
  baseUrl = window.location.protocol + "//" + window.location.host;
}

export const applyBaseColor = (colorCode) => {
  document.documentElement.style.setProperty('--green', colorCode);
};

export const applySecondaryColor = (colorCode) => {
  document.documentElement.style.setProperty('--yellow', colorCode);
};

export const openInNewTab = url => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

export const redBlackBetType = {
  Black, 
  Club, 
  Diamond,   
  Heart, 
  Joker,   
  Red, 
  Spade,   
};
export const fiftyCards = {
  "1": c1,
  "2": c2,
  "3": c3,
  "4": c4,
  "5": c5,
  "6": c6,
  "7": c7,
  "8": c8,
  "9": c9,
  "10": c10,
  "11": c11,
  "12": c12,
  "13": c13,
  "14": c14,
  "15": c15,
  "16": c16,
  "17": c17,
  "18": c18,
  "19": c19,
  "20": c20,
  "21": c21,
  "22": c22,
  "23": c23,
  "24": c24,
  "25": c25,
  "26": c26,
  "27": c27,
  "28": c28,
  "29": c29,
  "30": c30,
  "31": c31,
  "32": c32,
  "33": c33,
  "34": c34,
  "35": c35,
  "36": c36,
  "37": c37,
  "38": c38,
  "39": c39,
  "40": c40,
  "41": c41,
  "42": c42,
  "43": c43,
  "44": c44,
  "45": c45,
  "46": c46,
  "47": c47,
  "48": c48,
  "49": c49,
  "50": c50,
  "51": c51,
  "52": c52,
  "53": c53,
  "54": c54,
};




