import React, { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Table, Button } from "react-bootstrap";
import Header from "../../../Components/Header";
import { redBlackCurrentGame, redBlackGameLast20Result, restRecord } from "../../../reducers/commonReducer";
import SideBaar from "../../../Components/SideBaar";
import { fiftyCards, redBlackBetType } from "../../../const";

const RedBlackCurrentGame = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const gameId = queryParameters.get("id");

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(restRecord());
    dispatch(
      redBlackCurrentGame({
        gameHistory: gameId,
      })
    );
    dispatch(redBlackGameLast20Result())
    if(!gameId){
      const interval = setInterval(() => {
        dispatch(
          redBlackCurrentGame({
            gameHistory: gameId,
          })
        );
      }, 3000);
      return () => clearInterval(interval);
    }
  }, []);


  const { redBlackCurrentList, redBlackGameLast20List } = useSelector((state) => state.commonReducer);

  return (
    <>
      <Row>
        <Col md={2} className="p-0">
          <SideBaar />
        </Col>
        <Col md={10}>
          <Header />
          <div className="container">
            <Row className="text-center">
              <Col md={6}>
                <h3>Total Bettings: {redBlackCurrentList?.totalBettings}</h3>
              </Col>
              <Col md={6}>
                <h3>Total Winning: {redBlackCurrentList?.totalWinning}</h3>
              </Col>
            </Row>
            <hr className="mt-0"/>
            <Row className="mt-2">
              <Col md={2} sm={12} xs={12}>
                <Button
                  className="w-100 mbl-history-btn"
                  variant="success"
                  onClick={() => navigate(`/red-black-game-history`)}
                >
                  <b>
                    Game History <i className="bi bi-eye-fill"></i>
                  </b>
                </Button>
              </Col>
              <Col md={10} sm={12} xs={12} className="d-flex justify-content-end">
              {gameId ? <img className="bg-success p-1 small-cards mx-1 shadow" src={fiftyCards?.[redBlackCurrentList?.number]} alt={redBlackCurrentList?.number}/> : !!redBlackGameLast20List && redBlackGameLast20List?.map((card, index) => {
                  return(
                    <Fragment key={index} >
                      <img className="small-cards mx-1 shadow card-mbl" src={fiftyCards[card?.number]} alt={card?.number}/>
                    </Fragment>
                  )
                })
              }
              </Col>
            </Row>
            <Row className="justify-content-center">
              {!!redBlackCurrentList?.betTypes &&
                Object.entries(redBlackCurrentList.betTypes).map(
                  ([key, value]) => {
                    return (
                      <Col md={3} className="mt-3" key={key}>
                        <div className="box-shadow text-center">
                          <img
                            src={`${redBlackBetType[key]}`} 
                            alt={key}
                            className="w-100"
                          />
                          <div className="d-flex justify-content-between px-3 align-items-center">
                            <div className="yellow-circle bg-warning rounded-circle">
                              <h4 className="p-1 mt-1">
                                <b>{redBlackCurrentList?.bettingTpes[key]}</b>
                              </h4>
                            </div>
                            <h4 className="text-success">
                              <b>{value}</b>
                            </h4>
                          </div>
                        </div>
                      </Col>
                    );
                  }
                )}
            </Row>
            <Row className="my-2">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>UserName</th>
                    <th>Amount</th>
                    <th>Winning</th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr> */}
                    {
                      !!redBlackCurrentList?.currentUsersData && redBlackCurrentList?.currentUsersData?.map((item, index) =>{
                        return(
                          <tr key={index}>
                             <td className={`cursor-pointer ${item?.prime ? `text-success font-weight-bold` : ``}`} onClick={() => navigate(`/user-transaction?userid=${item?.id}&username=${item?.username}&userType=${item?.prime}`)}>{item?.name} ({item?.username})</td>
                             <td>{item?.coins}</td>
                             <td>{item?.winning}</td>
                          </tr>
                        )
                      })
                    }
                </tbody>
              </Table>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default RedBlackCurrentGame;
