import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import {
  getQuestions,
  getTickets,
  restRecord,
} from "../../reducers/commonReducer";

function TicketAction(props) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { btnTitle, btnFloat, id, status, reply, api } = props;
  return (
    <>
      <Button variant="success" onClick={handleShow} className={btnFloat}>
        {btnTitle ? btnTitle : <i className="bi bi-pen-fill"></i>}
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{btnTitle ? btnTitle : "Update"}</Modal.Title>
        </Modal.Header>
        <Formik
          onSubmit={async (values, { resetForm }) => {
            const apiresult = await dispatch(api(values));
            if (apiresult?.payload?.status) {
              resetForm({ values: "" });
              dispatch(restRecord());
              dispatch(getTickets());
              setShow(false);
            }
          }}
          initialValues={{
            id,
            status,
            reply
          }}
        >
          {({ handleSubmit, values, setFieldValue, errors }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3 mt-3">
                <Col md={12}>
                  <Form.Group>
                    <Form.Label>Select Status</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={(e) => setFieldValue("status", +e.target.value)}
                      name="status"
                      className="form-control"
                      value={values.status}
                      isInvalid={!!errors.status}
                    >
                      <option value="">Select Status</option>
                      <option value={0}>Pending</option>
                      <option value={1}>Progress</option>
                      <option value={2}>Sorted</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.status}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group>
                    <Form.Label>Reply</Form.Label>
                    <Form.Control
                      as="textarea"
                      onChange={(e) => setFieldValue("reply", e.target.value)}
                      name="reply"
                      className="form-control"
                      placeholder="Write......"
                      value={values.reply}
                      isInvalid={!!errors.reply}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.reply}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default TicketAction;
